const state = {
  autoSelectScannerResult: true,
  selectedReaderTypes: [
    'code_128_reader',
    'code_39_reader',
    'code_39_vin_reader'
  ],
  frequency: 10,
  selectedResolution: {
    width: 1280,
    height: 720
  },
  selectedVideoInputDevice: null,
  locator: {
    halfSample: true,
    patchSize: 'medium'
  },
  locate: true,
  numOfWorkers: 4,
  selectedHeaders: [],
  computerSelectedHeaders: [],
  mobileSelectedHeaders: [],
  simSelectedHeaders: [],
  securityDeviceSelectedHeaders: [],
  desktopDeploymentSelectedHeaders: [],
  laptopDeploymentSelectedHeaders: [],
  pcAccessorySelectedHeaders: [],
  pcRepairSelectedHeaders: [],
  pcUserChangeOnlySelectedHeaders: [],
  pcUserChangeSelectedHeaders: [],
  pcOsRefreshSelectedHeaders: [],
  pcReturnSelectedHeaders: [],
  assetHeaders: {},
  workflowTicketHeaders: {},
  importChunkSize: 10,
  importThreadCount: 8
}

const getters = {
  getDisplayedHeaders(state) {
    return type =>
      state.assetHeaders[type]
        .filter(h => h.show)
        .concat({
          text: 'Actions',
          value: 'action',
          sortable: false,
          fixed: true
        })
  },
  getWorkflowTicketDisplayedHeaders(state) {
    return type =>
      _.sortBy(state.workflowTicketHeaders[type]
        .filter(h => h.show),
        x => x.index)
  }
}

const actions = {}

const mutations = {
  setImportChunkSize(state, importChunkSize) {
    state.importChunkSize = importChunkSize
  },
  setImportThreadCount(state, importThreadCount) {
    state.importThreadCount = importThreadCount
  },
  setAutoSelectScannerResult(state, autoSelectScannerResult) {
    state.autoSelectScannerResult = autoSelectScannerResult
  },
  setSelectedReaderTypes(state, selectedReaderTypes) {
    state.selectedReaderTypes = selectedReaderTypes
  },
  setFrequency(state, frequency) {
    state.frequency = frequency
  },
  setSelectedResolution(state, selectedResolution) {
    state.selectedResolution = selectedResolution
  },
  setSelectedVideoInputDevice(state, selectedVideoInputDevice) {
    state.selectedVideoInputDevice = selectedVideoInputDevice
  },
  setHalfSample(state, halfSample) {
    state.locator.halfSample = halfSample
  },
  setPatchSize(state, patchSize) {
    state.locator.patchSize = patchSize
  },
  setLocate(state, locate) {
    state.locate = locate
  },
  setNumOfWorkers(state, numOfWorkers) {
    state.numOfWorkers = numOfWorkers
  },
  setSelectedHeaders(state, selectedHeaders) {
    state.selectedHeaders = selectedHeaders
  },
  setComputerSelectedHeaders(state, selectedHeaders) {
    state.computerSelectedHeaders = selectedHeaders
  },
  setMobileSelectedHeaders(state, selectedHeaders) {
    state.mobileSelectedHeaders = selectedHeaders
  },
  setSimSelectedHeaders(state, selectedHeaders) {
    state.simSelectedHeaders = selectedHeaders
  },
  setSecurityDeviceSelectedHeaders(state, selectedHeaders) {
    state.securityDeviceSelectedHeaders = selectedHeaders
  },
  setAssetHeaders(state, assetHeaders) {
    state.assetHeaders = assetHeaders
  },
  setDesktopDeploymentSelectedHeaders(state, selectedHeaders) {
    state.desktopDeploymentSelectedHeaders = selectedHeaders
  },
  setLaptopDeploymentSelectedHeaders(state, selectedHeaders) {
    state.laptopDeploymentSelectedHeaders = selectedHeaders
  },
  setPcAccessorySelectedHeaders(state, selectedHeaders) {
    state.pcAccessorySelectedHeaders = selectedHeaders
  },
  setPcRepairSelectedHeaders(state, selectedHeaders) {
    state.pcRepairSelectedHeaders = selectedHeaders
  },
  setPcUserChangeOnlySelectedHeaders(state, selectedHeaders) {
    state.pcUserChangeOnlySelectedHeaders = selectedHeaders
  },
  setPcUserChangeSelectedHeaders(state, selectedHeaders) {
    state.pcUserChangeSelectedHeaders = selectedHeaders
  },
  setPcOsRefreshSelectedHeaders(state, selectedHeaders) {
    state.pcOsRefreshSelectedHeaders = selectedHeaders
  },
  setPcReturnSelectedHeaders(state, selectedHeaders) {
    state.pcReturnSelectedHeaders = selectedHeaders
  },
  setWorkflowTicketHeaders(state, workflowTicketHeaders) {
    state.workflowTicketHeaders = workflowTicketHeaders
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
