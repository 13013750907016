export default {
  methods: {
    searchOnPaste(e) {
      let v = ''
      if (e.clipboardData && e.clipboardData.getData) {
        v = e.clipboardData.getData('text/plain')
      } else if (window.clipboardData && window.clipboardData.getData) {
        v = window.clipboardData.getData('Text')
      }
      this.search = v.trim()
    },
    async updateClipboard(newClip) {
      return navigator.clipboard.writeText(newClip)
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
  }
}
