import allAssets from './allAssets.js'
import computer from './computer.js'
import mobile from './mobile.js'
import sim from './sim.js'
import securityDevice from './securityDevice.js'

export default [
  allAssets,
  computer,
  mobile,
  sim,
  securityDevice
]
