export default {
  computed: {
    snackbar: {
      get() {
        return this.$store.state.appSnackbar
      },
      set(v) {
        this.$store.commit('setAppSnackbar', v)
      }
    }
  }
}
