import getEnv from '@/utils/env'
import { mapGetters } from 'vuex'

export default {
  computed: {
    appTitle() {
      return `${getEnv('VUE_APP_TITLE')}`
    },
    ...mapGetters('auth', ['loggedIn'])
  }
}
