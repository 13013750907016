import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './auth.module.js'
import user from './user.module.js'
import graphql from './graphql.module.js'
import router from './router.module.js'
import error from './error.module.js'
import settings from './settings.module.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { auth, user, graphql, router, error, settings },
  state: {
    appSnackbar: {
      show: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'bottom'
    }
  },
  mutations: {
    setAppSnackbar(state, payload) {
      if (payload.text) {
        state.appSnackbar = Object.assign(
          {
            show: true,
            color: '',
            mode: '',
            text: '',
            timeout: 3000,
            x: null,
            y: 'bottom'
          },
          payload
        )
      } else {
        console.warn('Cannot display a snackbar without message.')
      }
    }
  },
  plugins: [createPersistedState({ paths: ['settings'] })]
})
