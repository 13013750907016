const commonFields = [
  {
    name: 'id',
    label: 'Id',
    type: 'Id',
    readOnly: true,
    filterable: false
  },
  {
    name: 'externalId',
    label: 'External Id',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'name',
    label: 'Name',
    type: 'String',
    readOnly: true,
    filterable: true
  },
  {
    name: 'applicant',
    label: 'Applicant',
    type: 'String',
    readOnly: true,
    filterable: true
  },
  {
    name: 'creator',
    label: 'Creator',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'location',
    label: 'Location',
    type: 'String',
    readOnly: true,
    filterable: true
  },
  {
    name: 'aplikoStatus',
    label: 'Apliko Status',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'remarks',
    label: 'Remarks',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'updatedAt',
    label: 'Updated At',
    type: 'DateTimeOffset',
    readOnly: true,
    filterable: true
  },
  {
    name: 'requestedDeliveryDate',
    label: 'Requested Delivery Date',
    type: 'DateTimeOffset',
    readOnly: true,
    filterable: true
  },
  {
    name: 'fileAttachments',
    label: 'File Attachments',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'status',
    label: 'Status',
    type: 'Status',
    readOnly: false,
    filterable: true
  },
  {
    name: 'memo',
    label: 'Memo',
    type: 'String',
    readOnly: false,
    filterable: true
  }
]

export default [
  {
    name: 'assets',
    graphTypeName: 'Asset',
    listQueryName: 'assets',
    singleQueryName: 'asset',
    label: 'Asset',
    fields: [
      {
        name: 'assignee',
        type: 'Assignee'
      },
      {
        name: 'primaryUser',
        type: 'IntraAccount'
      },
      {
        name: 'histories',
        type: '[History]'
      },
      {
        name: 'id',
        type: 'ID!'
      },
      {
        name: 'location',
        type: 'Location'
      },
      {
        name: 'company',
        type: 'Company'
      },
      {
        name: 'memo',
        type: 'String'
      },
  
      {
        name: 'costCode',
        type: 'String'
      },
      {
        name: 'model',
        type: 'Model'
      },
      {
        name: 'name',
        type: 'String!'
      },
      {
        name: 'purchaseCost',
        type: 'Decimal'
      },
      {
        name: 'purchaseCurrencyCode',
        type: 'CurrencyCode'
      },
      {
        name: 'purchaseDate',
        type: 'Date'
      },
      {
        name: 'serialNumber',
        type: 'String'
      },
      {
        name: 'status',
        type: 'AssetStatus!'
      },
      {
        name: 'operationStatus',
        type: 'OperationStatus!'
      },
      {
        name: 'warrantyExpires',
        type: 'Date'
      }
    ],
    children: ['computer', 'mobile', 'sim', 'securityDevice']
  },
  {
    name: 'workflowTickets',
    graphTypeName: 'WorkflowTicket',
    listQueryName: 'workflowTickets',
    singleQueryName: 'workflowTicket',
    label: 'Workflow Ticket',
    fields: commonFields.concat(
      {
        name: 'discriminator',
        label: 'Type',
        type: 'String',
        readOnly: false,
        filterable: true
      }
    ),
    children: ['pcWorkflowTicketTypes', 'mobileWorkflowTicketTypes']
  },
  {
    name: 'desktopDeployments',
    graphTypeName: 'DesktopDeployment',
    listQueryName: 'desktopDeployments',
    singleQueryName: 'desktopDeployment',
    label: 'Desktop Deployment',
    fields: commonFields.concat([
      {
        name: 'operatorComputer',
        type: 'Computer',
        readOnly: false,
        extra: true,
        filterable: true,
        label: 'Which computer will be assigned?',
        fields: [
          'id',
          'name',
          'serialNumber'
        ]
      },
      {
        name: 'operatorAssignee',
        type: 'Assignee',
        readOnly: false,
        extra: true,
        label: 'Who will use this computer?',
        fields: [
          'id',
          'name',
          {
            type: 'IntraAccount',
            fields: ['samAccountName', 'employeeId'],
            isFragment: true 
          }
        ]
      },
      {
        name: 'computer',
        type: 'Computer',
        readOnly: true,
        extra: true,
        filterable: true,
        fields: [
          'id',
          'name',
          'serialNumber'
        ]
      },
      {
        name: 'operatorCostCode',
        type: 'String',
        readOnly: false,
        extra: true,
        label: 'What is the cost code of this computer?'
      },
      {
        name: 'isNewPc',
        type: 'Boolean',
        readOnly: false,
        extra: true,
        label: 'Is this a new computer?'
      },
      {
        name: 'costCode',
        type: 'String',
        readOnly: true,
        extra: true
      },
      {
        name: 'proxyApplication',
        type: 'Boolean',
        readOnly: true,
        extra: true
      },
      {
        name: 'os',
        type: 'String',
        readOnly: true,
        extra: true
      },
      {
        name: 'specifications',
        type: 'String',
        readOnly: true,
        extra: true
      },
      {
        name: 'monitor',
        type: 'String',
        readOnly: true,
        extra: true
      },
      {
        name: 'computerId',
        type: 'Id',
        readOnly: true,
        extra: true
      },
      {
        name: 'applyForUsername',
        type: 'String',
        readOnly: true,
        extra: true
      },
      {
        name: 'applicationCircumstance',
        type: 'String',
        readOnly: true,
        extra: true
      }
    ])
  },
  {
    name: 'pcAccessories',
    label: 'Pc Accessory',
    graphTypeName: 'PcAccessory',
    listQueryName: 'pcAccessories',
    singleQueryName: 'pcAccessorie',
    fields: commonFields.concat([
      {
        name: 'costCode',
        label: 'Cost Code',
        type: 'String',
        readOnly: true,
        extra: true
      },
      {
        name: 'units',
        label: 'Units',
        type: 'String',
        readOnly: true,
        extra: true
      },
      {
        name: 'productName',
        label: 'Product Name',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'applicationType',
        label: 'Application Type',
        type: 'ApplicationType',
        readOnly: true,
        extra: true
      }
    ])
  }
]