import assetTypes
 from './AssetTypes/index.js'
import allWorkflowTicketTypes from './WorkflowTicketTypes/index.js'
import reportTypes from './reportTypes.js'
const state = {
  assetType: '',
  workflowTicketType: '',
  pcWorkflowTicketType: '',
  mobileWorkflowTicketType: '',
  reportTypes: ''
}

const getters = {
  assetTypes() {
    return assetTypes
  },
  assetType(state) {
    return assetTypes.find(x => x.name === state.assetType)
  },
  workflowTicketTypes() {
    return allWorkflowTicketTypes.workflowTicketTypes
  },
  workflowTicketType(state) {
    return allWorkflowTicketTypes.workflowTicketTypes.find(x => x.name === state.workflowTicketType)
  },
  pcWorkflowTicketTypes() {
    return allWorkflowTicketTypes.pcWorkflowTicketTypes
  },
  pcWorkflowTicketType(state) {
    return allWorkflowTicketTypes.pcWorkflowTicketTypes.find(x => x.name === state.pcWorkflowTicketType)
  },
  mobileWorkflowTicketTypes() {
    return allWorkflowTicketTypes.mobileWorkflowTicketTypes
  },
  mobileWorkflowTicketType(state) {
    return allWorkflowTicketTypes.mobileWorkflowTicketTypes.find(x => x.name === state.mobileWorkflowTicketType)
  },
  reportTypes() {
    return reportTypes
  },
  reportType(state) {
    return reportTypes.find(x => x.name === state.reportType)
  }
}

const mutations = {
  setAssetType(state, type) {
    state.assetType = type
  },
  setWorkflowTicketType(state, type) {
    state.workflowTicketType = type
  },
  setPcWorkflowTicketType(state, type) {
    state.pcWorkflowTicketType = type
  },
  setMobileWorkflowTicketType(state, type) {
    state.mobileWorkflowTicketType = type
  },
  setReportType(state, type) {
    state.reportType = type
  }
}

const graphql = {
  namespaced: true,
  state,
  getters,
  mutations
}

export default graphql
