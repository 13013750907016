import Vue from 'vue'
import Router from 'vue-router'
import OktaVue from '@okta/okta-vue'
import { OktaAuth } from '@okta/okta-auth-js'
import getEnv from '@/utils/env'

const oktaAuth = new OktaAuth({
  issuer: getEnv('VUE_APP_OKTA_URL'),
  clientId: getEnv('VUE_APP_OKTA_CLIENT_ID'),
  redirectUri: `${location.origin}/login/callback`,
  postLogoutRedirectUri: `${location.origin}/guest`,
  scopes: ['openid', 'profile', getEnv('VUE_APP_OKTA_SCOPE')],
  tokenManager: {
    storage: 'sessionStorage'
  }
})
Vue.use(Router)
Vue.use(OktaVue, { oktaAuth })

const OktaService = {
  getAccessToken() {
    return this.$auth.getAccessToken()
  },
  login(redirect) {
    this.$auth.signInWithRedirect({ originalUri: redirect || '/' })
  },
  async logout(redirectState = '/') {
    await this.$auth.signOut({
      postLogoutRedirectUri: `${location.origin}/guest`,
      state: encodeURIComponent(redirectState)
    })
  }
}

OktaService.$auth = Vue.prototype.$auth

export default OktaService
