import Vue from 'vue'
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'

const tagRegex = /(?<![\p{L}\p{Nd}\p{Mn}\p{Pc}#])#\p{L}[\p{L}\p{Nd}\p{Mn}\p{Pc}]*(?![\p{L}\p{Nd}\p{Mn}\p{Pc}#])/u

VueCodemirror.CodeMirror.defineMode('tag', () => {
  const isValidTagCharacter = (ch, isFirst = false) => {
    if (_.isEmpty(ch)) {
      return false
    }
    if (isFirst) {
      return /\p{L}/u.test(ch)
    } else {
      return /\p{L}|\p{Nd}|\p{Mn}|\p{Pc}/u.test(ch)
    }
  }
  const hasTag = s => tagRegex.test(s)
  return {
    startState: () => ({ word: '' }),
    token: (stream, state) => {
      const addText = s => {
        state.word += s
        if (/\s/.test(s) || stream.eol()) {
          state.word = ''
        }
      }

      const ch = stream.next()
      if (ch === '#') {
        // test if this hashtag symbol could be a valid hashtag
        if (!hasTag(`${state.word}#a`) || (!isValidTagCharacter(stream.peek(), true) && !stream.eol())) {
          addText(ch)
          return 'invalid-tag-symbol'
        } else if (stream.eol()) {
          state.word = ''
          return 'valid-tag-symbol'
        } else {
          stream.eatWhile(c => isValidTagCharacter(c))
          if (!hasTag(state.word + stream.current())) {
            addText(stream.current())
            return null
          }
          addText(stream.current())
          return 'tag'
        }
      } else {
        addText(ch)
      }
      return null
    }
  }
})
Vue.use(VueCodemirror)
Object.defineProperty(Vue.prototype, '$CodeMirror', { value: VueCodemirror.CodeMirror })
