import ApiService from '@/services/api.service'
import OktaService from '@/services/okta.service'
import moment from 'moment'

const getTokenExp = t => {
  try {
    return t ? JSON.parse(
      decodeURIComponent(
        atob(
          t
            .split('.')[1]
            .replace(/-/g, '+')
            .replace(/_/g, '/')
        )
          .split('')
          .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      )
    ).exp : 0
  } catch (error) {
    console.error('Failed to parse token.', t)
    return 0
  }
}

const SKIP_AUTH = process.env.VUE_APP_SKIP_AUTH === '1'

const state = {
  token: '',
  tokenExpirationTime: 0,
  tokenTimeout: null,
  logoutInterval: null
}

const getters = {
  loggedIn: state => {
    return SKIP_AUTH || !!state.token
  },
  tokenExpired: state => {
    return moment.unix(state.tokenExpirationTime).diff(moment()) <= 0
  }
}

const actions = {
  async logout({ state }, redirectState = '/') {
    ApiService.removeHeader()
    clearInterval(state.logoutInterval)
    await OktaService.logout(redirectState)
  },
  async updateToken({ commit, getters, dispatch }) {
    if (SKIP_AUTH) {
      commit('setToken', '')
      ApiService.removeHeader()
      await dispatch('user/getUser', null, { root: true })
    } else {
      const authenticated = await OktaService.$auth.isAuthenticated()
      if (authenticated) {
        const isLoggedIn = !getters.loggedIn

        const token = OktaService.$auth.getAccessToken()
        commit('setToken', token)
        if (token) {
          ApiService.setHeader(token)

          const diff = moment.unix(state.tokenExpirationTime).diff(moment())
          if (diff > 0) {
            commit(
              'setTokenTimeout',
              setTimeout(() => {
                dispatch('updateToken')
              }, diff)
            )
          }
        }

        // get user info on login
        if (isLoggedIn) {
          await dispatch('user/getUser', null, { root: true })
        }
      } else {
        commit('setToken', '')
      }
    }
  }
}

const mutations = {
  setToken(state, token) {
    state.token = token
    state.tokenExpirationTime = getTokenExp(token)
  },
  setTokenTimeout(state, timeout) {
    if (state.tokenTimeout) {
      clearTimeout(state.tokenTimeout)
    }
    state.tokenTimeout = timeout
  },
  setLogoutInterval(state, logoutInterval) {
    state.logoutInterval = logoutInterval
  }
}

const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default auth
