const commonFields = [
  {
    name: 'id',
    type: 'Id',
    readOnly: true,
    filterable: false
  },
  {
    name: 'externalId',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'name',
    type: 'String',
    readOnly: true,
    filterable: true
  },
  {
    name: 'applicant',
    type: 'String',
    readOnly: true,
    filterable: true
  },
  {
    name: 'applicantEmployeeId',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'creator',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'location',
    type: 'String',
    readOnly: true,
    filterable: true
  },
  {
    name: 'aplikoStatus',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'remarks',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'updatedAt',
    type: 'DateTimeOffset',
    readOnly: true,
    filterable: true
  },
  {
    name: 'requestedDeliveryDate',
    type: 'DateTimeOffset',
    readOnly: true,
    filterable: true
  },
  {
    name: 'fileAttachments',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'status',
    type: 'Status',
    readOnly: false,
    filterable: true
  },
  {
    name: 'memo',
    type: 'String',
    readOnly: false,
    filterable: true
  }
]

const commonHeaders = [
  {
    index: 0,
    text: 'Document Number',
    value: 'name'
  },
  {
    index: 1,
    text: 'Applicant',
    value: 'applicant'
  },
  {
    index: 2,
    text: 'Location',
    value: 'location'
  },
  {
    index: 3,
    text: 'Last Update',
    value: 'updatedAt'
  },
  {
    index: 4,
    text: 'Status',
    value: 'status'
  },
  {
    index: 5,
    text: 'Requested Delivery Date',
    value: 'requestedDeliveryDate'
  },
  {
    index: 6,
    text: 'Memo',
    value: 'memo'
  }
]
export default [
  {
    name: 'allPcTickets',
    icon: null,
    graphTypeName: 'WorkflowTicket',
    listQueryName: 'workflowTickets',
    singleQueryName: 'workflowTicket',
    fields: commonFields.concat({
      name: 'discriminator',
      type: 'String',
      readOnly: true,
      extra: true,
      filterable: true
    }),
    headers: commonHeaders.concat({
      text: 'Type',
      value: 'discriminator',
      fixed: true
    })
  },
  {
    name: 'desktopDeployments',
    rawsId: 'fmPCL001',
    icon: 'mdi-desktop-tower-monitor',
    graphTypeName: 'DesktopDeployment',
    listQueryName: 'desktopDeployments',
    singleQueryName: 'desktopDeployment',
    mailTemplateTypeName: 'Desktop Deployment',
    update: {
      mutationName: 'updateDesktopDeployment',
      inputType: 'UpdateDesktopDeploymentInput!'
    },
    fields: commonFields.concat([
      {
        name: 'operatorComputer',
        type: 'Computer',
        readOnly: false,
        extra: true,
        filterable: false,
        label: 'Which computer will be assigned?',
        fields: [
          'id',
          'name',
          'alternativeName',
          'serialNumber',
          {
            name: 'model',
            type: 'Model',
            fields: [
              'id',
              'name',
              {
                name: 'manufacturer',
                type: 'Manufacturer',
                fields: ['id', 'name']
              }
            ]
          }
        ]
      },
      {
        name: 'operatorAssignee',
        type: 'Assignee',
        readOnly: false,
        extra: true,
        label: 'Who will use this computer?',
        fields: [
          'id',
          'name',
          {
            type: 'IntraAccount',
            fields: ['samAccountName', 'employeeId'],
            isFragment: true
          }
        ]
      },
      {
        name: 'operatorCostCode',
        type: 'String',
        readOnly: false,
        extra: true,
        label: 'What is the cost code of this computer?'
      },
      {
        name: 'isNewPc',
        type: 'Boolean',
        readOnly: false,
        extra: true,
        label: 'Is this a new computer?'
      },
      {
        name: 'bulkApplicationNumber',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'costCode',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'proxyApplication',
        type: 'Boolean',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'os',
        type: 'String',
        readOnly: true,
        extra: true
      },
      {
        name: 'pcCondition',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'specifications',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'monitor',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'applyForUsername',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'applyForEmployeeNumber',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'notificationAdEmails',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'applicationCircumstance',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      }
    ]),
    headers: _.sortBy(
      commonHeaders.concat(
        {
          index: 1,
          text: 'Bulk Application Number',
          value: 'bulkApplicationNumber'
        },
        {
          index: 1,
          text: 'Operator Computer',
          value: 'operatorComputer'
        },
        {
          index: 1,
          text: 'PC Condition',
          value: 'pcCondition'
        },
        {
          index: 1,
          text: 'Specifications',
          value: 'specifications'
        },
        {
          index: 1,
          text: 'OS',
          value: 'os'
        },
        {
          index: 1,
          text: 'Monitor',
          value: 'monitor'
        }
      ),
      (x) => x.index
    )
  },
  {
    name: 'laptopDeployments',
    rawsId: 'fmPCL002',
    icon: 'mdi-laptop',
    graphTypeName: 'LaptopDeployment',
    listQueryName: 'laptopDeployments',
    singleQueryName: 'laptopDeployment',
    mailTemplateTypeName: 'Laptop Deployment',
    update: {
      mutationName: 'updateLaptopDeployment',
      inputType: 'UpdateLaptopDeploymentInput!'
    },
    fields: commonFields.concat([
      {
        name: 'operatorComputer',
        type: 'Computer',
        readOnly: false,
        extra: true,
        filterable: false,
        label: 'Which computer will be assigned?',
        fields: [
          'id',
          'name',
          'serialNumber',
          {
            name: 'model',
            type: 'Model',
            fields: ['id', 'name']
          }
        ]
      },
      {
        name: 'operatorAssignee',
        type: 'Assignee',
        readOnly: false,
        extra: true,
        label: 'Who will use this computer?',
        fields: [
          'id',
          'name',
          {
            type: 'IntraAccount',
            fields: ['samAccountName', 'employeeId'],
            isFragment: true
          }
        ]
      },
      {
        name: 'operatorCostCode',
        type: 'String',
        readOnly: false,
        extra: true,
        label: 'What is the cost code of this computer?'
      },
      {
        name: 'operatorSim',
        type: 'Sim',
        readOnly: false,
        extra: true,
        label: 'Which sim will be assigned?',
        fields: [
          'id',
          'name',
          'serialNumber'
        ]
      },
      {
        name: 'costCode',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'bulkApplicationNumber',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'proxyApplication',
        type: 'Boolean',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'applyForUsername',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'applyForEmployeeNumber',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'notificationAdEmails',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'os',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'pcCondition',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'specifications',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'accountNotCreated',
        type: 'Boolean',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'employeeType',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      }
    ]),
    headers: _.sortBy(
      commonHeaders.concat(
        {
          index: 1,
          text: 'Bulk Application Number',
          value: 'bulkApplicationNumber'
        },
        {
          index: 1,
          text: 'Operator Computer',
          value: 'operatorComputer'
        },
        {
          index: 1,
          text: 'PC Condition',
          value: 'pcCondition'
        },
        {
          index: 1,
          text: 'Specifications',
          value: 'specifications'
        },
        {
          index: 1,
          text: 'OS',
          value: 'os'
        }
      ),
      (x) => x.index
    )
  },
  {
    name: 'pcAccessories',
    rawsId: 'fmPCL003',
    icon: 'mdi-usb',
    graphTypeName: 'PcAccessory',
    listQueryName: 'pcAccessories',
    singleQueryName: 'pcAccessory',
    mailTemplateTypeName: 'Pc Accessory',
    update: {
      mutationName: 'updatePcAccessory',
      inputType: 'UpdatePcAccessoryInput!'
    },
    fields: commonFields.concat([
      {
        name: 'costCode',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'units',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'category',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'manufacturer',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'productName',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'applicationType',
        type: 'ApplicationType',
        readOnly: true,
        extra: true,
        filterable: false
      },
      {
        name: 'notificationAdEmails',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      }
    ]),
    headers: _.sortBy(
      commonHeaders.concat(
        {
          index: 1,
          text: 'Units',
          value: 'units'
        },
        {
          index: 1,
          text: 'Category',
          value: 'category'
        },
        {
          index: 1,
          text: 'Manufacturer',
          value: 'manufacturer'
        },
        {
          index: 1,
          text: 'Product Name',
          value: 'productName'
        }
      ),
      (x) => x.index
    )
  },
  {
    name: 'pcRepairs',
    rawsId: 'fmPCL004',
    icon: 'mdi-hammer-wrench',
    graphTypeName: 'PcRepair',
    listQueryName: 'pcRepairs',
    singleQueryName: 'pcRepair',
    mailTemplateTypeName: 'Pc Repair',
    update: {
      mutationName: 'updatePcRepair',
      inputType: 'UpdatePcRepairInput!'
    },
    fields: commonFields.concat([
      {
        name: 'pcNumber',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'notificationAdEmails',
        type: 'String',
        readOnly: true,
        extra: true
      },
      {
        name: 'applicationCircumstance',
        type: 'String',
        readOnly: true,
        extra: true
      },
      {
        name: 'operatorComputer',
        type: 'Computer',
        readOnly: false,
        extra: true,
        filterable: false,
        label: 'Which computer will be repaired?',
        fields: [
          'id',
          'name',
          'serialNumber',
          {
            name: 'model',
            type: 'Model',
            fields: ['id', 'name']
          }
        ]
      },
      {
        name: 'misInquiryNumber',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'isPaidRepair',
        type: 'Boolean',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'costCode',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      }
    ]),
    headers: _.sortBy(
      commonHeaders.concat(
        {
          index: 1,
          text: 'Operator Computer',
          value: 'operatorComputer'
        },
        {
          index: 1,
          text: 'Model',
          value: 'operatorComputer.model.name'
        }
      ),
      (x) => x.index
    )
  },
  {
    name: 'pcUserChangesOnly',
    rawsId: 'fmPCL005',
    icon: 'mdi-account-convert',
    graphTypeName: 'PcUserChange',
    listQueryName: 'pcUserChanges',
    singleQueryName: 'pcUserChange',
    mailTemplateTypeName: 'Pc User Change',
    update: {
      mutationName: 'updatePcUserChange',
      inputType: 'UpdatePcUserChangeInput!'
    },
    fields: commonFields.concat([
      {
        name: 'pcNumber',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'operatorComputer',
        type: 'Computer',
        readOnly: false,
        extra: true,
        filterable: false,
        label: 'Which computer will be reassigned?',
        fields: [
          'id',
          'name',
          'serialNumber',
          {
            name: 'model',
            type: 'Model',
            fields: ['id', 'name']
          }
        ]
      },
      {
        name: 'model',
        type: 'Model',
        readOnly: true,
        extra: true
      },
      {
        name: 'newUserName',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'osReinstallation',
        type: 'Boolean',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'applicationCircumstance',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'hireDate',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'employeeType',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'operatorAssignee',
        type: 'Assignee',
        readOnly: false,
        extra: true,
        label: 'Who will be the new user of this computer?',
        fields: [
          'id',
          'name',
          {
            type: 'IntraAccount',
            fields: ['samAccountName', 'employeeId'],
            isFragment: true
          }
        ]
      }
    ]),
    headers: _.sortBy(
      commonHeaders.concat(
        {
          index: 1,
          text: 'Operator Computer',
          value: 'operatorComputer'
        },
        {
          index: 1,
          text: 'Model',
          value: 'operatorComputer.model.name'
        }
      ),
      (x) => x.index
    )
  },
  {
    name: 'pcUserChanges',
    rawsId: 'fmPCL005',
    icon: 'mdi-account-switch',
    graphTypeName: 'PcUserChange',
    listQueryName: 'pcUserChanges',
    singleQueryName: 'pcUserChange',
    mailTemplateTypeName: 'Pc User Change',
    update: {
      mutationName: 'updatePcUserChange',
      inputType: 'UpdatePcUserChangeInput!'
    },
    fields: commonFields.concat([
      {
        name: 'pcNumber',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'operatorComputer',
        type: 'Computer',
        extra: true,
        readOnly: false,
        fields: [
          'id',
          'name',
          'serialNumber',
          {
            name: 'model',
            type: 'Model',
            fields: ['id', 'name']
          }
        ],
        label: 'Which computer will be reassigned?'
      },
      {
        name: 'operatorAssignee',
        type: 'Assignee',
        readOnly: false,
        extra: true,
        label: 'Who will be the new user of this computer?',
        fields: [
          'id',
          'name',
          {
            type: 'IntraAccount',
            fields: ['samAccountName', 'employeeId'],
            isFragment: true
          }
        ]
      },
      {
        name: 'newUserName',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'osReinstallation',
        type: 'Boolean',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'applicationCircumstance',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      }
    ]),
    headers: _.sortBy(
      commonHeaders.concat(
        {
          index: 1,
          text: 'Operator Computer',
          value: 'operatorComputer'
        },
        {
          index: 1,
          text: 'Model',
          value: 'operatorComputer.model.name'
        }
      ),
      (x) => x.index
    )
  },
  {
    name: 'pcOsRefreshes',
    rawsId: 'fmPCL006',
    icon: 'mdi-refresh',
    graphTypeName: 'PcOsRefresh',
    listQueryName: 'pcOsRefreshes',
    singleQueryName: 'pcOsRefresh',
    mailTemplateTypeName: 'Pc Os Refresh',
    update: {
      mutationName: 'updatePcOsRefresh',
      inputType: 'UpdatePcOsRefreshInput!'
    },
    fields: commonFields.concat([
      {
        name: 'pcNumber',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'applicationCircumstance',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'operatorComputer',
        type: 'Computer',
        extra: true,
        readOnly: false,
        fields: [
          'id',
          'name',
          'serialNumber',
          {
            name: 'model',
            type: 'Model',
            fields: ['id', 'name']
          }
        ],
        label: 'Which computer will be OS refreshed?'
      }
    ]),
    headers: _.sortBy(
      commonHeaders.concat(
        {
          index: 1,
          text: 'Operator Computer',
          value: 'operatorComputer'
        },
        {
          index: 1,
          text: 'Model',
          value: 'operatorComputer.model.name'
        }
      ),
      (x) => x.index
    )
  },
  {
    name: 'pcReturns',
    rawsId: 'fmPCL007',
    icon: 'mdi-undo-variant',
    graphTypeName: 'PcReturn',
    listQueryName: 'pcReturns',
    singleQueryName: 'pcReturn',
    mailTemplateTypeName: 'Pc Return',
    update: {
      mutationName: 'updatePcReturn',
      inputType: 'UpdatePcReturnInput!'
    },
    fields: commonFields.concat([
      {
        name: 'pcNumber',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'applicationCircumstance',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'operatorComputer',
        type: 'Computer',
        extra: true,
        readOnly: false,
        fields: [
          'id',
          'name',
          'serialNumber',
          {
            name: 'model',
            type: 'Model',
            fields: ['id', 'name']
          }
        ],
        label: 'Which computer will be returned?'
      },
      {
        name: 'personReturningPc',
        type: 'String',
        extra: true,
        readOnly: true,
        filterable: true
      }
    ]),
    headers: _.sortBy(
      commonHeaders.concat(
        {
          index: 1,
          text: 'Operator Computer',
          value: 'operatorComputer'
        },
        {
          index: 1,
          text: 'Model',
          value: 'operatorComputer.model.name'
        }
      ),
      (x) => x.index
    )
  }
]
