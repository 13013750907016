<template>
  <v-overlay :value="true">
    <v-layout align-center justify-center column>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <template v-if="message">
        <br />
        <p v-text="message"></p>
      </template>
    </v-layout>
  </v-overlay>
</template>
<script>
export default {
  props: ['message']
}
</script>
