import OktaService from '@/services/okta.service'
import ErrorMixin from '@/mixins/error.mixin'
import { mapActions, mapMutations } from 'vuex'

export default {
  mixins: [ErrorMixin],
  data() {
    return {
      countdownInterval: null,
      countdown: 0,
      timeout: 60 * 5
    }
  },
  watch: {
    loginExpired() {
      this.onLoginExpiredChange()
    }
  },
  methods: {
    login() {
      OktaService.login(this.$route.fullPath)
    },
    formatCountdown(v) {
      return v > 60
        ? `${Math.floor(v / 60)} minutes and ${v % 60} seconds`
        : `${v % 60} seconds`
    },
    onLoginExpiredChange() {
      if (this.loginExpired) {
        this.countdown = this.timeout
        clearInterval(this.countdownInterval)
        this.countdownInterval = setInterval(() => {
          if (--this.countdown <= 0) {
            clearInterval(this.countdownInterval)
            // ensure logout is done correctly
            const logoutInterval = setInterval(
              () => this.logout(this.$route.fullPath),
              1000
            )
            this.setLogoutInterval(logoutInterval)
          }
        }, 1000)
      } else {
        clearInterval(this.countdownInterval)
      }
    },
    ...mapMutations('auth', ['setLogoutInterval']),
    ...mapActions('auth', ['logout'])
  },
  created() {
    this.onLoginExpiredChange()
  }
}
