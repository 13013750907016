const commonFields = [
  {
    name: 'id',
    type: 'Id',
    readOnly: true,
    filterable: false
  },
  {
    name: 'externalId',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'name',
    type: 'String',
    readOnly: true,
    filterable: true
  },
  {
    name: 'applicant',
    type: 'String',
    readOnly: true,
    filterable: true
  },
  {
    name: 'applicantEmployeeId',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'creator',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'location',
    type: 'String',
    readOnly: true,
    filterable: true
  },
  {
    name: 'aplikoStatus',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'remarks',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'createdAt',
    type: 'DateTimeOffset',
    readOnly: true,
    filterable: true
  },
  {
    name: 'updatedAt',
    type: 'DateTimeOffset',
    readOnly: true,
    filterable: true
  },
  {
    name: 'requestedDeliveryDate',
    type: 'DateTimeOffset',
    readOnly: true,
    filterable: true
  },
  {
    name: 'fileAttachments',
    type: 'String',
    readOnly: true,
    filterable: false
  },
  {
    name: 'status',
    type: 'Status',
    readOnly: false,
    filterable: true
  },
  {
    name: 'memo',
    type: 'String',
    readOnly: false,
    filterable: true
  }
]

const commonHeaders = [
  {
    text: 'Document Number',
    value: 'name'
  },
  {
    text: 'Applicant',
    value: 'applicant'
  },
  {
    text: 'Location',
    value: 'location'
  },
  {
    text: 'Memo',
    value: 'memo'
  },
  {
    text: 'Date Created',
    value: 'createdAt'
  },
  {
    text: 'Last Update',
    value: 'updatedAt'
  },
  {
    text: 'Requested Delivery Date',
    value: 'requestedDeliveryDate'
  },
  {
    text: 'Status',
    value: 'status'
  }
]

export default [
  {
    name: 'allMobileTickets',
    icon: null,
    graphTypeName: 'WorkflowTicket',
    listQueryName: 'workflowTickets',
    singleQueryName: 'workflowTicket',
    fields: commonFields.concat(
      {
        name: 'discriminator',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      }
    ),
    headers: commonHeaders.concat(
      {
        text: 'Type',
        value: 'discriminator',
        fixed: true
      }
    )
  },
  {
    name: 'mobileDeployments',
    rawsId: 'fmPCL008',
    icon: 'mdi-cellphone',
    graphTypeName: 'MobileDeployment',
    listQueryName: 'mobileDeployments',
    singleQueryName: 'mobileDeployment',
    mailTemplateTypeName: 'Mobile Deployment',
    update: {
      mutationName: 'updateMobileDeployment',
      inputType: 'UpdateMobileDeploymentInput!'
    },
    label: 'Mobile Deployment',
    fields: commonFields.concat([
      {
        name: 'costCode',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'deviceModel',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'deviceType',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'simType',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'reasonForApplyingVoiceSim',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'applicationCircumstance',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'operatorMobile',
        type: 'Mobile',
        readOnly: false,
        extra: true,
        filterable: false,
        label: 'Which mobile device will be assigned?',
        fields: [
          'id',
          'name',
          'serialNumber'
        ]
      },
      {
        name: 'operatorSim',
        type: 'Sim',
        readOnly: false,
        extra: true,
        label: 'Which sim will be assigned?',
        fields: [
          'id',
          'name',
          'serialNumber'
        ]
      },
      {
        name: 'operatorAssignee',
        type: 'Assignee',
        readOnly: false,
        extra: true,
        label: 'Who will use this mobile device/sim?',
        fields: [
          'id',
          'name',
          {
            type: 'IntraAccount',
            fields: ['samAccountName', 'employeeId'],
            isFragment: true 
          }
        ]
      }
    ]),
    headers: commonHeaders.concat(
      {
        text: 'Operator Mobile',
        value: 'operatorMobile.name'
      }
    )
  },
  {
    name: 'mobileUserChanges',
    rawsId: 'fmPCL009',
    icon: 'mdi-account-switch',
    graphTypeName: 'MobileUserChange',
    listQueryName: 'mobileUserChanges',
    singleQueryName: 'mobileUserChange',
    mailTemplateTypeName: 'Mobile User Change',
    update: {
      mutationName: 'updateMobileUserChange',
      inputType: 'UpdateMobileUserChangeInput!'
    },
    fields: commonFields.concat([
      {
        name: 'applicationCircumstance',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'costCode',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'newUser',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'deviceName',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true,
        filterable: true
      },
      {
        name: 'operatorMobile',
        type: 'Mobile',
        readOnly: false,
        extra: true,
        label: 'Which mobile device will be reassigned?',
        fields: [
          'id',
          'name',
          'serialNumber',
          {
            name: 'simSlots',
            type: 'SimSlot',
            fields: [
              'iMEI',
              {
                name: 'sim',
                type: 'Sim',
                fields: ['id', 'name', 'serialNumber']
              }
            ]
          }
        ]
      },
      {
        name: 'operatorOldSim',
        type: 'Sim',
        readOnly: false,
        extra: true,
        label: 'Which is the current sim of this mobile device?',
        fields: [
          'id',
          'name',
          'serialNumber'
        ]
      },
      {
        name: 'operatorNewSim',
        type: 'Sim',
        readOnly: false,
        extra: true,
        label: 'Which sim will replace the current sim?',
        fields: [
          'id',
          'name',
          'serialNumber'
        ]
      }
    ]),
    headers: commonHeaders.concat(
      {
        text: 'Operator Mobile',
        value: 'operatorMobile.name'
      }
    )
  },
  {
    name: 'mobileReturns',
    rawsId: 'fmPCL010',
    icon: 'mdi-undo-variant',
    graphTypeName: 'MobileReturn',
    listQueryName: 'mobileReturns',
    singleQueryName: 'mobileReturn',
    mailTemplateTypeName: 'Mobile Return',
    update: {
      mutationName: 'updateMobileReturn',
      inputType: 'UpdateMobileReturnInput!'
    },
    fields: commonFields.concat([
      {
        name: 'operatorMobile',
        type: 'Mobile',
        readOnly: true,
        extra: true,
        fields: [
          'id',
          'name',
          'serialNumber'
        ]
      },
      {
        name: 'applicationCircumstance',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'isSimOnly',
        type: 'Boolean',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'operatorOldSim',
        type: 'Sim',
        readOnly: true,
        extra: true,
        fields: [
          'id',
          'name',
          'serialNumber'
        ]
      },
      {
        name: 'deviceName',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      }
    ]),
    headers: commonHeaders.concat(
      {
        text: 'Operator Mobile',
        value: 'operatorMobile.name'
      }
    )
  },
  {
    name: 'mobileSimChanges',
    rawsId: 'fmPCL011',
    icon: 'mdi-sim',
    graphTypeName: 'MobileSimChange',
    listQueryName: 'mobileSimChanges',
    singleQueryName: 'mobileSimChange',
    mailTemplateTypeName: 'Mobile Sim Change',
    update: {
      mutationName: 'updateMobileSimChange',
      inputType: 'UpdateMobileSimChangeInput!'
    },
    fields: commonFields.concat([
      {
        name: 'reasonForApplyingVoiceSim',
        type: 'String',
        readOnly: true,
        filterable: true
      },
      {
        name: 'applicationCircumstance',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'deviceName',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'costCode',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'requestType',
        type: 'String',
        readOnly: true,
        extra: true,
        filterable: true
      },
      {
        name: 'operatorMobile',
        type: 'Mobile',
        readOnly: false,
        extra: true,
        label: 'Which mobile device is currently used?',
        fields: [
          'id',
          'name',
          'serialNumber'
        ]
      },
      {
        name: 'operatorOldSim',
        type: 'Sim',
        readOnly: false,
        extra: true,
        label: 'Which is the current sim of this mobile device?',
        fields: [
          'id',
          'name',
          'serialNumber'
        ]
      },
      {
        name: 'operatorNewSim',
        type: 'Sim',
        readOnly: false,
        extra: true,
        label: 'Which sim will replace the current sim?',
        fields: [
          'id',
          'name',
          'serialNumber'
        ]
      }
    ]),
    headers: commonHeaders.concat(
      {
        text: 'Operator Mobile',
        value: 'operatorMobile.name'
      }
    )
  }
]
