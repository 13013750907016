import AppBar from '@/components/AppBar/AppBar.vue'
import AppSnackbar from '@/components/AppSnackbar/AppSnackbar.vue'
import Navigation from '@/components/Navigation/Navigation.vue'
import Scanner from '@/components/Scanner/Scanner.vue'
import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue'
import LoginExpiredAlert from '@/components/LoginExpiredAlert/LoginExpiredAlert.vue'
import ApiService from '@/services/api.service'
import ErrorMixin from '@/mixins/error.mixin'
import GraphQlMixin from '@/mixins/graphql.mixin'

import { mapGetters, mapActions } from 'vuex'

export default {
  mixins: [ErrorMixin, GraphQlMixin],
  components: {
    AppBar,
    AppSnackbar,
    Navigation,
    Scanner,
    FullScreenLoader,
    LoginExpiredAlert
  },
  data() {
    return {
      hasError: false
    }
  },
  computed: {
    loading() {
      // show spinner unless read App ability is set or current route is okta callback
      return Boolean(this.ability.cannot('read', 'App') && this.$route.path !== '/login/callback' && _.get(this, '$route.meta.requiresAuth', false))
    },
    ...mapGetters('auth', ['loggedIn'])
  },
  watch: {
    // Everytime the route changes, check for auth status
    $route() {
      this.updateToken()
      this.removeAllErrors()
    }
  },
  methods: {
    async init() {
      try {
        // Set the base URL of the API
        ApiService.init(process.env.VUE_APP_API_PATH)

        // make sure token is set for all services
        await this.updateToken()
        this.updateQuery(this.$route.query)
      } catch (e) {
        this.hasError = true
        console.error(e)
        this.renderError(500)
      }
    },
    ...mapActions('auth', ['updateToken']),
    ...mapActions('router', ['updateQuery'])
  },
  created() {
    this.init()

    // deprecate apollo-token
    localStorage.removeItem('apollo-token')
  }
}
