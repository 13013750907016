import OktaService from '@/services/okta.service'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

export default {
  computed: {
    loginExpired() {
      return (
        !this.loggedIn &&
        this.$route.matched.some((record) => record.meta.requiresAuth)
      )
    },
    ...mapGetters({ errors: 'error/errorsArray' }),
    ...mapGetters('auth', ['loggedIn'])
  },
  methods: {
    renderError(code) {
      switch (code) {
        case 403:
        case 404:
        case 500:
        case 503:
          const originalPath = this.$route.path
          this.$router.replace({ path: `/${code}` }, () => {
            this.$nextTick(() => {
              history.replaceState({}, null, originalPath)
            })
          })
          break
        default:
          console.error('Unknown error code', code)
      }
    },
    graphQLOnError(e) {
      if (e.toString().includes('You are not authenticated')) {
        OktaService.login(this.$route.fullPath)
      }
      else if (_.get(e, 'gqlError.message') === 'Not found') {
        this.renderError(404)
      }
      this.removeErrorByType('graphQL')
      this.addError(null, e.toString(), 'graphQL')
    },
    addError(id, message, type = 'general') {
      if (!id) {
        id = uuidv4()
      }
      this.$store.dispatch('error/addError', { id, message, type })
    },
    removeError(id) {
      this.$store.dispatch('error/removeError', id)
    },
    removeErrorByType(type) {
      this.$store.dispatch('error/removeErrorByType', type)
    },
    removeAllErrors() {
      this.$store.commit('error/setErrors', {})
    },
    onErrorAlertDismiss(error) {
      this.removeError(error.id)
    }
  }
}
