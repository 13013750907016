import _ from 'lodash'

const state = {
  errors: {}
}

const getters = {
  errorsArray: state => Object.keys(state.errors).map(id => ({ id, message: state.errors[id].message, type: state.errors[id].type }))
}

const actions = {
  addError({ state, commit }, { id, message, type }) {
    const errors = _.clone(state.errors)
    errors[id] = { message, type }
    commit('setErrors', errors)
  },
  removeError({ state, commit }, id) {
    const errors = _.clone(state.errors)
    delete errors[id]
    commit('setErrors', errors)
  },
  removeErrorByType({ state, commit }, type) {
    const errors = _.clone(state.errors)

    const ids = Object.keys(errors)
    for (let id of ids) {
      if (errors[id].type === type) {
        delete errors[id]
      }
    }
    commit('setErrors', errors)
  }
}

const mutations = {
  setErrors(state, errors) {
    state.errors = errors || {}
  }
}

const error = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default error
