import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import * as components from 'vuetify/lib/components'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  components,
  directives: {
    Ripple
  }
})

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  }
})
