import ApiService from '../services/api.service'
import OktaService from '../services/okta.service'
import UserService from '../services/user.service'
import { isEmpty, get } from 'lodash'
import { Ability, AbilityBuilder } from '@casl/ability'

const state = {
  userInfo: {},
  ability: new Ability(),
  avatar: require('../assets/default-avatar.gif')
}

const getters = {
  user: state => ({
    avatar: state.avatar,
    name: state.userInfo.name,
    employeeId: state.userInfo.employeeId,
    roles: !isEmpty(state.userInfo.roles) ? state.userInfo.roles.map(r => r.name).join(', ') : ''
  }),
  ability: state => state.ability
}

const actions = {
  async getUser({ state, commit }) {
    // get user info from okta
    const oktaUser = await OktaService.$auth.getUser()
    // get other user info from API
    const { data, error } = await UserService.getUserInfo()
    if (data) {
      // set user info
      commit('setUserInfo', {
        ...data.me,
        ...{ name: get(oktaUser, 'name') }
      })

      // set user ability
      const { rules, can, cannot } = AbilityBuilder.extract()
      cannot('manage', 'all')
      can('read', 'App')
      can('read', 'Role')
      can('read', 'Permission')

      state.userInfo.permissions.forEach(permission => {
        const f = permission.inverted ? cannot : can
        const subject = permission.subject[0]
        permission.actions.forEach(action => {
          switch (action) {
            case 'Query':
              if (!isEmpty(permission.regions)) {
                permission.regions.forEach(region => {
                  f('read', subject, permission.fields, { region })
                })
              } else {
                f('read', subject, permission.fields)
              }
              break
            case 'Mutate':
              const re = /^(create|update|enable|disable)(\w+)$/
              const groups = subject.match(re)

              if (groups !== null) {
                const caslAction = groups[1]
                const caslSubject = groups[2]

                if (!isEmpty(permission.regions)) {
                  permission.regions.forEach(region => {
                    f(caslAction, caslSubject, { region })
                  })
                } else {
                  f(caslAction, caslSubject)
                }
              }
              break
            case 'Import':
              f('import', subject)
              break
            case 'Upload':
            case 'Share':
              break
            default:
              console.error('unknown action: ', action, permission)
              break
          }
        })
      })

      if (data.me.roles.map((x) => x.name).includes('Admin') || data.me.roles.map((x) => x.name).includes('IT Manager')) {
        can('update', 'assetWithoutSerialNumber')
      }

      commit('setAbility', new Ability(rules, { subjectName: item => get(item, '__typename', item) }))

      let avatar = require('../assets/default-avatar.gif')
      if (data.me.avatarUrl !== null) {
        try {
          const response = await ApiService.customRequest({
            url: data.me.avatarUrl,
            responseType: 'arraybuffer'
          })
          const b64data = btoa(String.fromCharCode(...new Uint8Array(response.data)))
          const mimetype = response.headers['content-type']
          avatar = `data:${mimetype};base64,${b64data}`
        } catch (avatarError) {
          if (avatarError.response && avatarError.response.status == 404) {
            // noop: This is normal for users without avatars
          } else {
            console.error(avatarError)
          }
        }
      }
      commit('setAvatar', avatar)
    }
    if (error) {
      console.error(error)
    }
  }
}

const mutations = {
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
  },
  setAbility(state, ability) {
    state.ability = ability
  },
  setAvatar(state, avatar) {
    state.avatar = avatar
  }
}

const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default user
