export default {
  name: 'allAssets',
  icon: 'mdi-devices',
  graphTypeName: 'Asset',
  listQueryName: 'assets',
  singleQueryName: 'asset',
  fields: [
    { name: 'region', type: 'Region' },
    { name: 'countryIsoCode2', type: 'Country' },
    { name: 'assignee', type: 'Assignee' },
    { name: 'primaryUser', type: 'IntraAccount' },
    { name: 'histories', type: '[History]' },
    { name: 'id', type: 'ID!' },
    { name: 'office', type: 'Location' },
    { name: 'company', type: 'Company' },
    { name: 'memo', type: 'String' },
    { name: 'costCode', type: 'String' },
    { name: 'model', type: 'Model' },
    { name: 'name', type: 'String!' },
    { name: 'purchaseCost', type: 'Decimal' },
    { name: 'purchaseCurrencyCode', type: 'CurrencyCode' },
    { name: 'purchaseDate', type: 'Date' },
    { name: 'serialNumber', type: 'String' },
    { name: 'status', type: 'AssetStatus!' },
    { name: 'operationStatus', type: 'OperationStatus!' },
    { name: 'warrantyExpires', type: 'Date' },
    { name: 'isDeleted', type: 'Boolean!' },
    { name: 'isCloned', type: 'Boolean!' },
    { name: 'latestDeleteHistory', type: 'History' }
  ],
  headers: [
    { text: 'Type', value: 'model.assetType', fixed: true },
    { text: 'Name/Phone Number', value: 'name', fixed: true },
    { text: 'Serial Number/ICCID', value: 'serialNumber', fixed: true },
    { text: 'Assignee', value: 'assignee.samAccountName' },
    { text: 'PrimaryUser', value: 'primaryUser.samAccountName' },
    { text: 'Company', value: 'company.name' },
    { text: 'Manufacturer/Carrier', value: 'model.manufacturer.name' },
    { text: 'Memo', value: 'memo' },
    { text: 'Cost Code', value: 'costCode' },
    { text: 'Model', value: 'model.name' },
    { text: 'Model Number', value: 'model.modelNumber' },
    { text: 'Purchase Cost', value: 'purchaseCost' },
    { text: 'Purchase Currency Code', value: 'purchaseCurrencyCode' },
    { text: 'Purchase Date', value: 'purchaseDate' },
    { text: 'Region', value: 'region' },
    { text: 'Country', value: 'countryIsoCode2' },
    { text: 'Office', value: 'office.name' },
    { text: 'Status', value: 'status' },
    { text: 'Operation Status', value: 'operationStatus' },
    { text: 'Warranty Expires', value: 'warrantyExpires' }
  ]
}
