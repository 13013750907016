export default [
  {
    name: 'pcWorkflowTickets',
    icon: 'mdi-monitor',
    graphTypeName: 'PcWorkflowTicket',
    listQueryName: 'pcWorkflowTickets',
    singleQueryName: 'pcWorkflowTicket',
    label: 'PC Tickets',
    fields: [
      {
        name: 'id',
        type: 'Id'
      }
    ],
    headers: [
      {
        text: 'Id',
        value: 'id',
        fixed: true
      }
    ],
    children: 'pcWorkflowTicketTypes'
  },
  {
    name: 'mobileWorkflowTickets',
    icon: 'mdi-cellphone',
    graphTypeName: 'MobileWorkflowTicket',
    listQueryName: 'mobileWorkflowTickets',
    singleQueryName: 'mobileWorkflowTicket',
    label: 'Mobile Tickets',
    fields: [
      {
        name: 'id',
        type: 'Id'
      }
    ],
    headers: [
      {
        text: 'Id',
        value: 'id',
        fixed: true
      }
    ],
    children: 'mobileWorkflowTicketTypes'
  }
]
