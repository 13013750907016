export default {
  name: 'computers',
  icon: 'computer',
  graphTypeName: 'Computer',
  listQueryName: 'computers',
  singleQueryName: 'computer',
  create: { mutationName: 'createComputer', inputType: 'CreateComputerInput!' },
  update: { mutationName: 'updateComputer', inputType: 'UpdateComputerInput!' },
  fields: [
    { name: 'region', type: 'Region' },
    { name: 'countryIsoCode2', type: 'Country' },
    { name: 'assignee', type: 'Assignee' },
    { name: 'primaryUser', type: 'IntraAccount' },
    { name: 'histories', type: '[History]' },
    { name: 'id', type: 'ID!' },
    { name: 'office', type: 'Location' },
    { name: 'company', type: 'Company' },
    { name: 'memo', type: 'String' },
    { name: 'costCode', type: 'String' },
    { name: 'model', type: 'Model' },
    { name: 'name', type: 'String!' },
    { name: 'purchaseCost', type: 'Decimal' },
    { name: 'purchaseCurrencyCode', type: 'CurrencyCode' },
    { name: 'purchaseDate', type: 'Date' },
    { name: 'serialNumber', type: 'String' },
    { name: 'status', type: 'AssetStatus!' },
    { name: 'operationStatus', type: 'OperationStatus!' },
    { name: 'pclStatus', type: 'PclStatus!', extra: true, readOnly: false },
    { name: 'isLegalHold', type: 'Boolean', extra: true, readOnly: false },
    { name: 'shareStatus', type: 'ShareStatus!', extra: true, readOnly: false },
    { name: 'warrantyExpires', type: 'Date' },
    { name: 'alternativeName', type: 'String', extra: true, readOnly: false },
    { name: 'initialDeploymentDate', type: 'Date', extra: true, readOnly: false },
    { name: 'procurementRequestCode', displayName: 'Procurement Request Code (Ticket #)', type: 'String', extra: true, readOnly: false },
    { name: 'usage', type: 'ComputerUsage', extra: true, readOnly: false },
    { name: 'pcType', type: 'ComputerPcType', extra: true, readOnly: false },
    { name: 'pcSpecification', type: 'ComputerPcSpecification', extra: true, readOnly: false },
    {
      name: 'jamfComputer',
      type: 'JamfComputer',
      extra: false,
      readOnly: true,
      fields: [
        'udid',
        'name',
        'computerName',
        'lastUser',
        'serialNumber',
        'fileVaultStatus',
        'fileVault2Status',
        'model',
        'modelIdentifier',
        'operatingSystemVersion',
        'managed',
        'mDMCapability',
        'lastReportedIPAddress',
        'lastCheckIn',
        'lastInventoryUpdate',
        'deviceSite',
        'macAddress',
        'eFIPassword',
        'processor',
        'memory',
        'driveCapacity'
      ]
    },
    {
      name: 'intuneComputer',
      type: 'IntuneComputer',
      extra: false,
      readOnly: true,
      fields: [
        'deviceName',
        'groupTag',
        'serialNumber',
        'encryptionStatus',
        'model',
        'manufacturer',
        'enrolledDateTime',
        'lastUpdateDateTime',
        'lastSyncDateTime',
        'osVersion',
        'localIp',
        'wifiMacAddress',
        'ethernetMacAddress',
        'userPrincipalName'
      ]
    },
    { name: 'deviceEncryptionStatus', type: 'Boolean', extra: true, discovery: true, readOnly: true },
    { name: 'passwordForEncryption', type: 'Boolean', extra: true, discovery: true, readOnly: true },
    { name: 'lastCheckIn', type: 'DateTimeOffset', extra: true, discovery: true, readOnly: true },
    { name: 'lastInventoryCheck', type: 'DateTimeOffset', extra: true, discovery: true, readOnly: true },
    { name: 'macAddress', type: 'String', extra: true, discovery: true, readOnly: true },
    { name: 'osVersion', type: 'String', extra: true, discovery: true, readOnly: true },
    { name: 'lastUser', type: 'String', extra: true, discovery: true, readOnly: true },
    { name: 'initialEnrollDate', type: 'DateTimeOffset', extra: true, discovery: true, readOnly: true },
    { name: 'processor', type: 'String', extra: true, discovery: true, readOnly: true },
    { name: 'memory', type: 'String', extra: true, discovery: true, readOnly: true },
    { name: 'driveCapacity', type: 'String', extra: true, discovery: true, readOnly: true },
    {
      name: 'simSlots',
      extra: true,
      readOnly: false,
      displayName: 'Sims',
      type: '[SimSlot]',
      fields: [
        'id',
        'iMEI',
        {
          name: 'sim',
          extra: true,
          readOnly: false,
          type: 'Sim',
          fields: ['id', 'name']
        }
      ]
    }
  ],
  importFields: [
    'Id',
    'Name',
    'AlternativeName',
    'Status',
    'OperationStatus',
    'PclStatus',
    'ShareStatus',
    'LegalHold',
    'SerialNumber',
    'PurchaseDate',
    'PurchaseCost',
    'Manufacturer',
    'ModelName',
    'ModelNumber',
    'WarrantyExpires',
    'Company',
    'Region',
    'Country',
    'Office',
    'Assignee',
    'IMEI1',
    'PhoneNumber1',
    'IMEI2',
    'PhoneNumber2',
    'CostCode',
    'InitialDeploymentDate',
    'ProcurementRequestCode',
    'Usage',
    'PcType',
    'PcSpecification',
    'Memo'
  ],
  headers: [
    { text: 'Name', value: 'name', fixed: true, index: -1000 },
    { text: 'Alternative Name', value: 'alternativeName', fixed: true, index: -999 },
    { text: 'Serial Number', value: 'serialNumber', fixed: true, index: -998 },
    { text: 'Assignee', value: 'assignee.samAccountName' },
    { text: 'PrimaryUser', value: 'primaryUser.samAccountName' },
    { text: 'Company', value: 'company.name' },
    { text: 'Manufacturer', value: 'model.manufacturer.name' },
    { text: 'Memo', value: 'memo' },
    { text: 'Cost Code', value: 'costCode' },
    { text: 'Model', value: 'model.name' },
    { text: 'Model Number', value: 'model.modelNumber' },
    { text: 'Purchase Cost', value: 'purchaseCost' },
    { text: 'Purchase Currency Code', value: 'purchaseCurrencyCode' },
    { text: 'Purchase Date', value: 'purchaseDate' },
    { text: 'Region', value: 'region' },
    { text: 'Country', value: 'countryIsoCode2' },
    { text: 'Office', value: 'office.name' },
    { text: 'Status', value: 'status' },
    { text: 'Operation Status', value: 'operationStatus' },
    { text: 'Pcl Status', value: 'pclStatus' },
    { text: 'Share Status', value: 'shareStatus' },
    { text: 'Legal Hold', value: 'isLegalHold' },
    { text: 'Warranty Expires', value: 'warrantyExpires' },
    { text: 'Initial Deployment Date', value: 'initialDeploymentDate' },
    { text: 'Procurement Request Code', value: 'procurementRequestCode' },
    { text: 'Usage', value: 'usage' },
    { text: 'Device Encryption Status', value: 'deviceEncryptionStatus', sortable: false },
    { text: 'Password For Encryption', value: 'passwordForEncryption', sortable: false },
    { text: 'Last CheckIn', value: 'lastCheckIn', sortable: false },
    { text: 'Last Inventory Check', value: 'lastInventoryCheck', sortable: false },
    { text: 'Mac Address', value: 'macAddress', sortable: false },
    { text: 'Os Version', value: 'osVersion', sortable: false },
    { text: 'Last User', value: 'lastUser', sortable: false },
    { text: 'Initial Enroll Date', value: 'initialEnrollDate', sortable: false },
    { text: 'Processor', value: 'processor', sortable: false },
    { text: 'Memory', value: 'memory', sortable: false },
    { text: 'Drive Capacity', value: 'driveCapacity', sortable: false },
    { text: 'Pc Type', value: 'pcType' },
    { text: 'Pc Specification', value: 'pcSpecification' },
    {
      text: 'IMEIs',
      value: 'simSlots'
    },
    {
      text: 'Phone Numbers',
      value: 'simSlots'
    }
  ]
}
