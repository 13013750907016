import router from '../router.js'

const state = {
  query: {}
}

const getters = {
  query: state => state.query
}

const actions = {
  addQueryParameter({ dispatch, state }, newQuery) {
    const query = { ...state.query }

    // remove duplicated values, to prevent unnecessary update
    for (let key of Object.keys(newQuery)) {
      if (_.isEqual(query[key], newQuery[key])) {
        delete newQuery[key]
      }
    }
    if (Object.keys(newQuery).length > 0) {
      dispatch('updateQuery', { ...query, ...newQuery })
    }
  },
  removeQueryParameter({ dispatch, state }, key) {
    const query = { ...state.query }
    if (Object.keys(query).includes(key)) {
      delete query[key]
      dispatch('updateQuery', query)
    }
  },
  updateQuery({ state, commit }, query) {
    query = Object.assign({}, query)
    if (!_.isEqual(query, state.query)) {
      router.replace({ query }).catch(() => {})
      commit('setQuery', query)
    }
  }
}

const mutations = {
  setQuery(state, query) {
    state.query = query
  }
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
