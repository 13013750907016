import Me from '@/graphql/Me.gql'
import { createProvider } from '@/plugins/vue-apollo'

const UserService = {
  async getUserInfo() {
    return await createProvider().defaultClient.query({ query: Me })
  }
}

export default UserService
