export default 
{
  name: 'securityDevices',
  icon: 'vpn_key',
  graphTypeName: 'SecurityDevice',
  listQueryName: 'securityDevices',
  singleQueryName: 'securityDevice',
  create: {
    mutationName: 'createSecurityDevice',
    inputType: 'CreateSecurityDeviceInput!'
  },
  update: {
    mutationName: 'updateSecurityDevice',
    inputType: 'UpdateSecurityDeviceInput!'
  },
  fields: [
    {
      name: 'region',
      type: 'Region'
    },
    {
      name: 'countryIsoCode2',
      type: 'Country'
    },
    {
      name: 'assignee',
      type: 'Assignee'
    },
    {
      name: 'primaryUser',
      type: 'IntraAccount'
    },
    {
      name: 'histories',
      type: '[History]'
    },
    {
      name: 'id',
      type: 'ID!'
    },
    {
      name: 'office',
      type: 'Location'
    },
    {
      name: 'company',
      type: 'Company'
    },
    {
      name: 'memo',
      type: 'String'
    },
    {
      name: 'costCode',
      type: 'String'
    },
    {
      name: 'model',
      type: 'Model'
    },
    {
      name: 'name',
      type: 'String!'
    },
    {
      name: 'purchaseCost',
      type: 'Decimal'
    },
    {
      name: 'purchaseCurrencyCode',
      type: 'CurrencyCode'
    },
    {
      name: 'purchaseDate',
      type: 'Date'
    },
    {
      name: 'serialNumber',
      type: 'String'
    },
    {
      name: 'status',
      type: 'AssetStatus!'
    },
    {
      name: 'operationStatus',
      type: 'OperationStatus!'
    },
    {
      name: 'warrantyExpires',
      type: 'Date'
    },
    {
      name: 'initialDeploymentDate',
      type: 'Date',
      extra: true,
      readOnly: false
    }
  ],
  importFields: [
    'Id',
    'Name',
    'Status',
    'OperationStatus',
    'SerialNumber',
    'PurchaseDate',
    'PurchaseCost',
    'Manufacturer',
    'ModelName',
    'ModelNumber',
    'WarrantyExpires',
    'Company',
    'Region',
    'Country',
    'Office',
    'Assignee',
    'CostCode',
    'Memo',
    'InitialDeploymentDate'
  ],
  headers: [
    {
      text: 'Name',
      value: 'name',
      fixed: true
    },
    {
      text: 'Serial Number',
      value: 'serialNumber',
      fixed: true
    },
    {
      text: 'Assignee',
      value: 'assignee.samAccountName'
    },
    {
      text: 'PrimaryUser',
      value: 'primaryUser.samAccountName'
    },
    {
      text: 'Company',
      value: 'company.name'
    },
    {
      text: 'Manufacturer',
      value: 'model.manufacturer.name'
    },
    {
      text: 'Memo',
      value: 'memo'
    },
    {
      text: 'Cost Code',
      value: 'costCode'
    },
    {
      text: 'Model',
      value: 'model.name'
    },
    {
      text: 'Model Number',
      value: 'model.modelNumber'
    },
    {
      text: 'Purchase Cost',
      value: 'purchaseCost'
    },
    {
      text: 'Purchase Currency Code',
      value: 'purchaseCurrencyCode'
    },
    {
      text: 'Purchase Date',
      value: 'purchaseDate'
    },
    {
      text: 'Region',
      value: 'region'
    },
    {
      text: 'Country',
      value: 'countryIsoCode2'
    },
    {
      text: 'Office',
      value: 'office.name'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Operation Status',
      value: 'operationStatus'
    },
    {
      text: 'Warranty Expires',
      value: 'warrantyExpires'
    },
    {
      text: 'Initial Deployment Date',
      value: 'initialDeploymentDate'
    }
  ]
}
