import Quagga from 'quagga'
import { mapState } from 'vuex'

export default {
  props: ['show', 'result'],
  data() {
    return {
      detected: [],
      selected: null
    }
  },
  computed: {
    quaggaState() {
      const state = {
        inputStream: {
          type: 'LiveStream',
          constraints: {
            width: { min: _.get(this, 'selectedResolution.width', 1280) },
            height: { min: _.get(this, 'selectedResolution.height', 720) },
            facingMode: 'environment',
            aspectRatio: { min: 1, max: 2 }
          }
        },
        locator: this.locator,
        numOfWorkers: this.numOfWorkers,
        frequency: this.frequency,
        decoder: {
          readers: this.selectedReaderTypes
        },
        locate: this.locate
      }
      if (this.selectedVideoInputDevice) {
        state.inputStream.constraints.deviceId = this.selectedVideoInputDevice
      }
      return state
    },
    ...mapState({
      autoSelectScannerResult: state => state.settings.autoSelectScannerResult,
      selectedReaderTypes: state => state.settings.selectedReaderTypes,
      frequency: state => state.settings.frequency,
      selectedResolution: state => state.settings.selectedResolution,
      selectedVideoInputDevice: state => state.settings.selectedVideoInputDevice,
      locator: state => state.settings.locator,
      locate: state => state.settings.locate,
      numOfWorkers: state => state.settings.numOfWorkers
    })
  },
  watch: {
    show(v) {
      if (v) {
        this.detected = []
        this.$emit('update:result', null)
        this.$nextTick(this.start)
      }
    },
    quaggaState: {
      handler() {
        this.restart()
      },
      deep: true
    }
  },
  methods: {
    start() {
      try {
        Quagga.init(this.quaggaState, err => {
          if (err) {
            this.addError(null, err.toString())
            return console.error(err)
          }
          Quagga.start()
        })
        Quagga.onDetected(this.onDetected)
        Quagga.onProcessed(this.onProcessed)
      } catch (e) {
        console.error('Failed to start Quagga', e)
      }
    },
    stop() {
      try {
        Quagga.stop()
      } catch (e) {
        console.error('Failed to stop Quagga', e)
      }
    },
    restart() {
      if (this.show) {
        this.stop()
        this.start()
      }
    },
    onDetected(data) {
      const { code, format } = data.codeResult

      if (code && format) {
        let o = this.detected.find(d => d.code === code && d.format === format)
        if (_.isEmpty(o)) {
          this.detected.push({
            code,
            format,
            count: 1
          })
        } else {
          if (++o.count > 5 && this.autoSelectScannerResult) {
            this.emit(o.code)
          }
        }
      }
      this.detected = _.orderBy(this.detected, ['count', 'format', 'code'], 'desc')
    },
    onProcessed(result) {
      let drawingCtx = Quagga.canvas.ctx.overlay
      let drawingCanvas = Quagga.canvas.dom.overlay
      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute('width')),
            parseInt(drawingCanvas.getAttribute('height'))
          )
          result.boxes
            .filter(box => box !== result.box)
            .forEach(box =>
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: 'green',
                lineWidth: 2
              })
            )
        }
        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: '#00F',
            lineWidth: 2
          })
        }
        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 })
        }
      }
    },
    close() {
      this.stop()
      this.detected = []
      this.$emit('update:show', false)
    },
    emit(code) {
      this.$emit('update:result', code)
      this.close()
    }
  }
}
