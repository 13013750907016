import Vue from 'vue'
import App from './components/App/App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { createProvider } from './plugins/vue-apollo'
import { abilitiesPlugin } from '@casl/vue'
import JsMixin from '@/mixins/js.mixin'
import ErrorMixin from '@/mixins/error.mixin'

// javascript libraries
import moment from 'moment-timezone'
import lodash from 'lodash'
import lodashInflection from 'lodash-inflection'
import gql from 'graphql-tag'
import vueHeadful from 'vue-headful'
import './plugins/codemirror'

Object.defineProperty(Vue.prototype, '$moment', { value: moment })
Object.defineProperty(Vue.prototype, '_', { value: lodash })
Object.defineProperty(Vue.prototype, '$lodashInflection', { value: lodashInflection })
Object.defineProperty(Vue.prototype, '$gql', { value: gql })

Vue.use(abilitiesPlugin)
Vue.mixin(ErrorMixin)
Vue.mixin(JsMixin)
Vue.config.productionTip = false
Vue.component('vue-headful', vueHeadful)

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
