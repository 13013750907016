import Vue from 'vue'
import Router from 'vue-router'
import OktaService from './services/okta.service'
import getEnv from '@/utils/env'

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.

/* Okta callback landing page */
const LoginCallback = () =>
  import('./views/LoginCallback/LoginCallback.vue')

const Home = () => import('./views/Home/Home.vue')

/* Asset pages */
const Asset = () => import('./views/Asset/Asset.vue')
const ListAssets = () => import('./views/Asset/List/List.vue')
const ShowAsset = () => import('./views/Asset/Show/Show.vue')
const CreateAsset = () => import('./views/Asset/Create/Create.vue')
const EditAsset = () => import('./views/Asset/Edit/Edit.vue')

const Users = () => import('./views/Users/Users.vue')
const ListUsers = () => import('./views/Users/List/List.vue')
const ShowUser = () => import('./views/Users/Show/Show.vue')

const Locations = () => import('./views/Locations/Locations.vue')
const ListLocations = () => import('./views/Locations/List/List.vue')

const Models = () => import('./views/Models/Models.vue')
const ListModels = () => import('./views/Models/List/List.vue')
const CreateModel = () => import('./views/Models/Create/Create.vue')
const EditModel = () => import('./views/Models/Edit/Edit.vue')

const WorkflowTicket = () => import('./views/WorkflowTickets/WorkflowTickets.vue')
const ListWorkflowTickets = () => import('./views/WorkflowTickets/List/List.vue')
const EditWorkflowTicket = () => import('./views/WorkflowTickets/Edit/Edit.vue')

const Settings = () => import('./views/Settings/Settings.vue')
const ScannerSettings = () => import('./views/Settings/Scanner/Scanner.vue')

const Reports = () => import('./views/Reports/Reports.vue')
const ListReports = () => import('./views/Reports/List/List.vue')
const ShowReport = () => import('./views/Reports/Show/Show.vue')
const CreateReport = () => import('./views/Reports/Create/Create.vue')
const EditReport = () => import('./views/Reports/Edit/Edit.vue')

const Search = () => import('./views/Search/Search.vue')

const MailTemplates = () =>
  import('./views/Settings/MailTemplates/MailTemplates.vue')
const ListMailTemplates = () =>
  import('./views/Settings/MailTemplates/List/List.vue')
const CreateMailTemplate = () =>
  import('./views/Settings/MailTemplates/Create/Create.vue')
const EditMailTemplate = () =>
  import('./views/Settings/MailTemplates/Edit/Edit.vue')

const Discovery = () => import('./views/Settings/Discovery/Discovery.vue')
const ListCrowdstrikeDevices = () =>
  import('./views/Settings/Discovery/CrowdstrikeDevices/List/List.vue')
const ListJamfDevices = () =>
  import('./views/Settings/Discovery/JamfDevices/List/List.vue')

const Guest = () => import('./views/Guest/Guest.vue')
const Forbidden = () => import('./views/Errors/403.vue')
const NotFound = () => import('./views/Errors/404.vue')
const InternalServerError = () => import('./views/Errors/500.vue')
const ServiceUnavailable = () => import('./views/Errors/503.vue')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/login/callback', component: LoginCallback },
    { path: '/', component: Home, meta: { requiresAuth: true, title: 'Home' } },
    { path: '/assets', redirect: '/' },
    {
      path: '/assets/:type',
      component: Asset,
      props: true,
      meta: { requiresAuth: true },
      children: [
        {
          // when /assets/:type is matched
          path: '',
          name: 'list-assets',
          component: ListAssets,
          props: true,
          meta: { requiresAuth: true }
        },
        { path: 'filters', redirect: '/assets/:type' },
        {
          path: 'filters/:filterId',
          name: 'show-asset-filter',
          component: ListAssets,
          props: true,
          meta: { requiresAuth: true }
        },
        {
          // when /assets/:type/create is matched
          path: 'create',
          name: 'create-asset',
          component: CreateAsset,
          props: true,
          meta: { requiresAuth: true, title: 'Create Asset' }
        },
        {
          // when /assets/:id is matched
          path: ':id',
          name: 'show-asset',
          component: ShowAsset,
          props: true,
          meta: { requiresAuth: true, title: 'Asset' }
        },
        {
          // when /assets/:id/edit is matched
          path: ':id/edit',
          name: 'edit-asset',
          component: EditAsset,
          props: true,
          meta: { requiresAuth: true, title: 'Edit Asset' }
        }
      ]
    },
    {
      path: '/trash/:type',
      component: Asset,
      props: true,
      meta: { requiresAuth: true },
      children: [
        {
          // when /trash/:type is matched
          path: '',
          name: 'list-deleted-assets',
          component: ListAssets,
          props: true,
          meta: { requiresAuth: true }
        },
        {
          // when /trash/:id is matched
          path: ':id',
          name: 'show-deleted-asset',
          component: ShowAsset,
          props: true,
          meta: { requiresAuth: true, title: 'Asset' }
        }
      ]
    },
    {
      path: '/tickets/:type',
      component: WorkflowTicket,
      props: true,
      meta: { requiresAuth: true },
      children: [
        {
          // when /tickets/:type is matched
          path: '',
          name: 'list-workflow-tickets',
          component: ListWorkflowTickets,
          props: true,
          meta: { requiresAuth: true }
        },
        {
          // when /tickets/:id/edit is matched
          path: ':id/edit',
          name: 'edit-workflow-ticket',
          component: EditWorkflowTicket,
          props: true,
          meta: { requiresAuth: true, title: 'Edit Workflow Ticket' }
        }
      ]
    },
    {
      path: '/users',
      component: Users,
      props: true,
      meta: { requiresAuth: true },
      children: [
        {
          // when /users is matched
          path: '',
          name: 'list-users',
          component: ListUsers,
          props: true,
          meta: { requiresAuth: true, title: 'Users' }
        },
        {
          // when /users/:id is matched
          path: ':id',
          name: 'show-user',
          component: ShowUser,
          props: true,
          meta: { requiresAuth: true, title: 'User' }
        }
      ]
    },
    {
      path: '/locations',
      component: Locations,
      props: true,
      meta: { requiresAuth: true },
      children: [
        {
          // when /locations is matched
          path: '',
          name: 'list-locations',
          component: ListLocations,
          props: true,
          meta: { requiresAuth: true, title: 'Locations' }
        }
      ]
    },
    {
      path: '/models',
      component: Models,
      props: true,
      meta: { requiresAuth: true },
      children: [
        {
          // when /models is matched
          path: '',
          name: 'list-models',
          component: ListModels,
          props: true,
          meta: { requiresAuth: true, title: 'Models' }
        },
        {
          // when /models/:type/create is matched
          path: 'create',
          name: 'create-model',
          component: CreateModel,
          props: true,
          meta: { requiresAuth: true, title: 'Create Model' }
        },
        {
          // when /models/:id/edit is matched
          path: ':id/edit',
          name: 'edit-model',
          component: EditModel,
          props: true,
          meta: { requiresAuth: true, title: 'Edit Model' }
        }
      ]
    },
    {
      path: '/reports',
      component: Reports,
      props: true,
      meta: { requiresAuth: true },
      children: [
        {
          // when /reports is matched
          path: '',
          name: 'list-reports',
          component: ListReports,
          props: true,
          meta: { requiresAuth: true, title: 'Reports' }
        },
        {
          path: 'create',
          name: 'create-report',
          component: CreateReport,
          props: true,
          meta: { requiresAuth: true, title: 'Create Report' }
        },
        {
          // when /reports/:id/edit is matched
          path: ':id/edit',
          name: 'edit-report',
          component: EditReport,
          props: true,
          meta: { requiresAuth: true, title: 'Edit Report' }
        },
        {
          // when /reports/:id is matched
          path: ':id',
          name: 'show-report',
          component: ShowReport,
          props: true,
          meta: { requiresAuth: true, title: 'Show Report' }
        }
      ]
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: { requiresAuth: true, title: 'Settings' }
    },
    {
      path: '/settings/scanner',
      name: 'scannerSettings',
      component: ScannerSettings,
      meta: { requiresAuth: true, title: 'Scanner' }
    },
    {
      path: '/settings/discovery',
      component: Discovery,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'crowdstrike-devices',
          name: 'list-crowdstrike-devices',
          component: ListCrowdstrikeDevices,
          props: true,
          meta: { requiresAuth: true, title: 'Crowdstrike Devices' }
        },
        {
          path: 'jamf-devices',
          name: 'list-jamf-devices',
          component: ListJamfDevices,
          props: true,
          meta: { requiresAuth: true, title: 'Jamf Devices' }
        }
      ]
    },
    {
      path: '/settings/mail-templates',
      component: MailTemplates,
      meta: { requiresAuth: true },
      children: [
        {
          // when /mail-templates/:type is matched
          path: '',
          name: 'list-mail-templates',
          component: ListMailTemplates,
          props: true,
          meta: { requiresAuth: true, title: 'Mail Templates' }
        },
        {
          path: 'create',
          name: 'create-mail-template',
          component: CreateMailTemplate,
          props: true,
          meta: { requiresAuth: true, title: 'Create Mail Template' }
        },
        {
          // when /mail-templates/:id/edit is matched
          path: ':id/edit',
          name: 'edit-mail-template',
          component: EditMailTemplate,
          props: true,
          meta: { requiresAuth: true, title: 'Edit Mail Template' }
        }
      ]
    },
    {
      path: '/search',
      name: 'search',
      component: Search,
      meta: { requiresAuth: true, title: 'Search' }
    },
    { path: '/guest', name: 'guest', component: Guest, meta: { guest: true } },
    { path: '/403', name: 'forbidden', component: Forbidden },
    { path: '/404', name: 'not found', component: NotFound },
    {
      path: '/500',
      name: 'internal server error',
      component: InternalServerError
    },
    {
      path: '/503',
      name: 'service unavailable',
      component: ServiceUnavailable
    },
    { path: '*', redirect: '/404' }
  ]
})

router.beforeEach(async(to, from, next) => {
  if (process.env.VUE_APP_SKIP_AUTH !== '1') {
    const loggedIn = await OktaService.$auth.isAuthenticated()

    if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
      OktaService.login(to.fullPath)
      return
    } else if (to.matched.some((record) => record.meta.guest) && loggedIn) {
      next({ path: '/' })
    }
  }
  next()
})

const DEFAULT_TITLE = `${getEnv('VUE_APP_TITLE_SHORT')}`
router.afterEach((to, from) => {
  let title = ''

  if (to.params.type) {
    title = title.concat(`${_.startCase(to.params.type)} `)
  }

  if (to.meta.title) {
    title = title.concat(`${to.meta.title}`)
  }

  document.title = _.isEmpty(title)
    ? DEFAULT_TITLE
    : title.concat(` | ${DEFAULT_TITLE}`)
})

router.pushAsync = (route) => {
  return new Promise((resolve, reject) => {
    router.push(route, resolve, reject)
  })
}

export default router
