import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      drawer: true,
      mini: true
    }
  },
  computed: {
    items() {
      const items = []
      const assetChildren = this.$store.getters['graphql/assetTypes']
        .filter((type) => this.ability.can('read', type.graphTypeName))
        .map((assetType) => ({
          icon: assetType.icon,
          link: {
            name: 'list-assets',
            params: {
              type: assetType.name,
              filterProp: this.getAssetFilterProp(),
              searchProp: ''
            }
          },
          name:
            assetType.graphTypeName === 'Asset'
              ? 'allAssets'
              : assetType.graphTypeName,
          label:
            assetType.graphTypeName === 'Asset'
              ? 'All assets'
              : _.startCase(assetType.graphTypeName)
        }))

      if (!_.isEmpty(assetChildren)) {
        assetChildren.push({
          icon: 'delete',
          link: '/trash/allAssets',
          label: 'Trash',
          name: 'trash'
        })
        items.push({
          icon: 'computer',
          link: '/assets',
          label: 'Assets',
          name: 'assets',
          children: assetChildren
        })
      }

      const workflowChildren = this.$store.getters[
        'graphql/workflowTicketTypes'
      ].map((workflowTicketType) => ({
        icon: workflowTicketType.icon,
        link: '/tickets',
        label: _.startCase(workflowTicketType.label),
        name: workflowTicketType.name,
        children: this.getWorkflowChildren(workflowTicketType.children)
      }))

      if (this.ability.can('read', 'WorkflowTicket')) {
        items.push({
          icon: 'mdi-transit-connection-variant',
          link: '/tickets',
          label: 'Workflow',
          name: 'workflow',
          children: workflowChildren
        })
      }

      if (this.ability.can('read', 'Model')) {
        items.push({
          icon: 'mdi-format-list-bulleted-type',
          link: '/models',
          label: 'Models',
          name: 'models'
        })
      }

      if (this.ability.can('read', 'IntraAccount')) {
        items.push({
          icon: 'mdi-account-group-outline',
          link: '/users',
          label: 'Users',
          name: 'users'
        })
      }

      if (this.ability.can('read', 'Location')) {
        items.push({
          icon: 'mdi-map-marker-outline',
          link: '/locations',
          label: 'Locations',
          name: 'locations'
        })
      }

      if (this.ability.can('read', 'Report')) {
        items.push({
          icon: 'mdi-chart-timeline-variant',
          link: '/reports',
          label: 'Reports',
          name: 'reports'
        })
      }

      if (this.ability.can('read', 'App')) {
        items.push({
          icon: 'mdi-magnify',
          link: '/search',
          label: 'Search',
          name: 'Search'
        })
      }

      if (this.ability.can('read', 'Asset')) {
        items.push({
          icon: 'mdi-cog-outline',
          link: '/settings',
          label: 'Settings',
          name: 'settings'
        })
      }

      return items
    },
    ...mapGetters('user', ['user']),
    ...mapGetters({ ability: 'user/ability' })
  },
  methods: {
    ...mapActions('auth', ['logout']),
    getWorkflowChildren(type) {
      switch (type) {
        case 'pcWorkflowTicketTypes':
          return this.$store.getters['graphql/pcWorkflowTicketTypes'].map(
            (pcWorkflowTicketType) => ({
              icon: pcWorkflowTicketType.icon,
              link: {
                name: 'list-workflow-tickets',
                params: {
                  type: pcWorkflowTicketType.name,
                  filterProp: this.getWorkflowFilterProp(
                    pcWorkflowTicketType.name
                  )
                }
              },
              label: _.startCase(pcWorkflowTicketType.name),
              name: pcWorkflowTicketType.name
            })
          )
          break
        case 'mobileWorkflowTicketTypes':
          return this.$store.getters['graphql/mobileWorkflowTicketTypes'].map(
            (mobileWorkflowTicketType) => ({
              icon: mobileWorkflowTicketType.icon,
              link: {
                name: 'list-workflow-tickets',
                params: {
                  type: mobileWorkflowTicketType.name,
                  filterProp: this.getWorkflowFilterProp(
                    mobileWorkflowTicketType.name
                  )
                }
              },
              label: _.startCase(mobileWorkflowTicketType.name),
              name: mobileWorkflowTicketType.name
            })
          )
          break
        default:
          return null
      }
    },
    getAssetFilterProp() {
      return {
        negate: false,
        connector: 'and',
        filters: [],
        group: true,
        root: true,
        errors: [],
        filterType: 'Asset'
      }
    },
    getWorkflowFilterProp(item) {
      const inProgressFilter = {
        field: {
          text: 'Status',
          value: {
            filterable: true,
            name: 'status',
            readOnly: false,
            type: 'Status'
          }
        },
        operator: { text: 'in', value: 'in' },
        value: ['Waiting For Manager', 'Rejected', 'Completed'],
        negate: true,
        group: false,
        errors: []
      }
      const defaultFilter = {
        negate: false,
        connector: 'and',
        filters: [inProgressFilter],
        group: true,
        root: true,
        errors: [],
        filterType: 'WorkflowTicket'
      }
      if (item === 'pcUserChangesOnly') {
        return {
          negate: false,
          connector: 'and',
          filters: [
            inProgressFilter,
            {
              field: {
                text: 'Os Reinstallation',
                value: {
                  filterable: true,
                  name: 'osReinstallation',
                  readOnly: true,
                  type: 'Boolean',
                  extra: true
                }
              },
              operator: {
                text: '=',
                value: 'equal'
              },
              value: {
                name: 'No',
                value: false
              },
              negate: false,
              group: false,
              errors: []
            }
          ],
          group: true,
          root: true,
          errors: [],
          filterType: 'WorkflowTicket'
        }
      } else if (item === 'pcUserChanges') {
        return {
          negate: false,
          connector: 'and',
          filters: [
            inProgressFilter,
            {
              field: {
                text: 'Os Reinstallation',
                value: {
                  filterable: true,
                  name: 'osReinstallation',
                  readOnly: true,
                  type: 'Boolean',
                  extra: true
                }
              },
              operator: {
                text: '=',
                value: 'equal'
              },
              value: {
                name: 'Yes',
                value: true
              },
              negate: false,
              group: false,
              errors: []
            }
          ],
          group: true,
          root: true,
          errors: [],
          filterType: 'WorkflowTicket'
        }
      } else if (item === 'allPcTickets') {
        return {
          negate: false,
          connector: 'and',
          filters: [
            {
              field: {
                text: 'Type',
                value: {
                  filterable: true,
                  name: 'discriminator',
                  readOnly: true,
                  type: 'String',
                  extra: true
                }
              },
              operator: {
                text: 'in',
                value: 'in'
              },
              value: [
                'DesktopDeployment',
                'LaptopDeployment',
                'PcAccessory',
                'PcRepair',
                'PcUserChange',
                'PcOsRefresh',
                'PcReturn'
              ],
              negate: false,
              group: false,
              errors: []
            }
          ],
          group: true,
          root: true,
          errors: [],
          filterType: 'WorkflowTicket'
        }
      } else if (item === 'allMobileTickets') {
        return {
          negate: false,
          connector: 'and',
          filters: [
            {
              field: {
                text: 'Type',
                value: {
                  filterable: true,
                  name: 'discriminator',
                  readOnly: true,
                  type: 'String',
                  extra: true
                }
              },
              operator: {
                text: 'in',
                value: 'in'
              },
              value: [
                'MobileDeployment',
                'MobileUserChange',
                'MobileReturn',
                'MobileSimChange'
              ],
              negate: false,
              group: false,
              errors: []
            }
          ],
          group: true,
          root: true,
          errors: [],
          filterType: 'WorkflowTicket'
        }
      } else {
        return defaultFilter
      }
    }
  }
}
