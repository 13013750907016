import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', ['ability'])
  },
  methods: {
    getFieldsGql(action, subject, allFields) {
      return allFields
        .map(f => {
          if (typeof f === 'string') {
            var fieldNameProperCase = f.charAt(0).toUpperCase() + f.slice(1)
            if (subject === 'ValueUpdate' || subject === 'HistoryItem' || subject === '[SimSlot]' || subject === 'SimSlot' || subject === 'Sim') {
              return f
            }
            else if (this.ability.can(action, subject, f) || this.ability.can(action, subject, fieldNameProperCase)) {
              //with Graph QL, the field name is case sensitive, as of v4.
              return f
            }
          } else if (typeof f === 'object') {
            if (f.isFragment) {
              const fields = this.getFieldsGql(action, f.type, f.fields)
              if (!_.isEmpty(fields)) {
                return `... on ${f.type} { ${fields} }`
              }
            } else {
              var fieldNameProperCase = f.name.charAt(0).toUpperCase() + f.name.slice(1)
              if (this.ability.can(action, subject.replace(/[[\]]/g,''), f.name) || this.ability.can(action, subject.replace(/[[\]]/g,''), fieldNameProperCase)) {
                const fields = this.getFieldsGql(action, f.type, f.fields)
                if (!_.isEmpty(fields)) {
                  return `${f.name} { ${fields} }`
                }
              }
            }
          }
          return null
        })
        .filter(f => f)
        .join(' ')
    },
    getOrderBy() {
      const { sortBy, sortDesc } = this.options
      const orderBy = []
      for (let i = 0; i < sortBy.length; i++) {
        orderBy.push({ path: sortBy[i], descending: sortDesc[i] })
      }
      return orderBy
    }
  }
}
